<template>
  <CCard>
    <CCardHeader>
      <strong>{{ titulo }}</strong>
    </CCardHeader>
    <CCardBody>
      <CForm :items="cuestionario">
        <CInput
          id="title"
          description="Título del cuestionario"
          label="Título del cuestionario"
          horizontal
          :value="cuestionario.title"
          :disabled="informacion"
        />
        <CInput
          id="description"
          description="Descricpción del cuestionario"
          label="Descripción del cuestionario"
          horizontal
          :value="cuestionario.description"
          :disabled="informacion"
        />
        <CRow>
          <CCol sm="3">
            Vídeo
          </CCol>
          <CCol sm="3">
            <div v-if="informacion">
              <select
                id="videos"
                class="form-select"
                :disabled="informacion"
              >
                <option
                  v-for="video in infoVideo"
                  :key="video.video_id"
                  :value="video.video_id"
                >
                  {{ video.video_name }} | {{ video.category_name }}
                </option>
              </select>
            </div>

            <div v-if="!informacion">
              <select
                id="videos"
                class="form-select"
                :disabled="informacion"
              >
                <option
                  v-for="video in videos"
                  :key="video.video_id"
                  :value="video.video_id"
                >
                  {{ video.video_name }} | {{ video.category_name }}
                </option>
              </select>
            </div>
          </CCol>
        </CRow>
        <template v-if="id">
          <div
            class="d-flex justify-content-center"
            style="margin-top: 10px"
          >
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="togglePreguntas()"
            >
              {{ showQuestions ? "Ocultar preguntas" : "Mostrar Preguntas" }}
            </CButton>
          </div>
        </template>
        <CCollapse
          :show="showQuestions"
          :duration="collapseDuration"
        >
          <template
            v-for="pregunta in cuestionario.questions"
          >
            <fieldset
              :key="pregunta.quest_question_id"
              class="border p-2"
            >
              <legend class="w-auto"> 
                Pregunta
              </legend>
              <CInput
                :key="pregunta.quest_question_id"
                :question_id="pregunta.quest_question_id"
                :new_question_id="pregunta.new_question_id"
                :placeholder="(!isNaN(pregunta.new_question_id) && !informacion) ? 'Las preguntas en blanco no se añadirán al cuestionario' : ''"
                class="question"
                :value="(pregunta.questions == undefined && informacion) ? 'VACIO. SIN PREGUNTAS.' : pregunta.questions"
                :disabled="informacion"
              />

              <CButton
                v-if="!informacion && !isNaN(id)"
                color="danger"
                variant="outline"
                size="sm"
                @click="mostrarPregDeleteModal(pregunta)"
              >
                Eliminar pregunta
              </CButton>
              <div
                v-if="id"
                class="d-flex justify-content-center"
              >
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  @click="toggleRespuestas(pregunta.quest_question_id, pregunta.new_question_id)"
                >
                  {{ pregunta.answer._toggled ? "Ocultar respuestas" : "Mostrar respuestas" }}
                </CButton>
              </div>
              <CCollapse
                :show="(typeof pregunta.answer._toggled !== undefined) && (pregunta.answer._toggled)"
                :duration="collapseDuration"
              >
                <fieldset
                  :key="pregunta.quest_question_id"
                  class="border p-2"
                >
                  <legend class="w-auto"> 
                    Respuestas
                  </legend>
                  <CRow
                    v-for="respuesta in pregunta.answer"
                    :key="respuesta.quest_answer_id"
                  >
                    <CCol :sm="(respuesta.quest_answer_id && !informacion) ? 11 : 12">
                      <CInput
                        :answer_id="respuesta.quest_answer_id"
                        :question_id="pregunta.quest_question_id"
                        :new_question_id="pregunta.new_question_id"
                        :placeholder="(isNaN(respuesta.quest_answer_id) && !informacion) ? 'Las respuestas en blanco no se añadirán al cuestionario' : ''"
                        class="answer"
                        :value="(respuesta.answer == undefined && informacion) ? 'VACIO. SIN RESPUESTAS.': respuesta.answer"
                        :disabled="informacion"
                      />
                    </CCol>
                    <CCol>
                      <CButton
                        v-if="!informacion && respuesta.quest_answer_id && !isNaN(id)"
                        color="danger"
                        variant="outline"
                        size="sm"  
                        @click="mostrarResDeleteModal(respuesta, pregunta.quest_question_id)"
                      >
                        Eliminar
                      </CButton>
                    </CCol>
                  </CRow>
                </fieldset>

                <div
                  v-if="!informacion"
                  class="d-flex justify-content-center"
                  style="margin-top: 10px"
                > 
                  <CButton
                    color="primary"
                    square
                    size="sm"
                    @click="addAnswer(pregunta.quest_question_id, pregunta.new_question_id)"
                  >
                    Añadir una respuesta
                  </CButton>
                </div>
              </CCollapse>
            </fieldset>
          </template>
          <component
            :is="newQuestion"
            v-for="(newQuestion, index) in newQuestions"
            :key="index"
          />
          <div
            v-if="!informacion"
            class="d-flex justify-content-center"
            style="margin-top: 20px"
          > 
            <CButton
              color="primary"
              square
              size="sm"
              @click="addQuestion()"
            >
              Añadir una pregunta
            </CButton>
          </div>
        </CCollapse>
      </CForm>
      <div
        class="d-flex justify-content-center"
        style="margin-top: 20px"
      >
        <CButton
          class="btn btn-secondary"
          @click="volver()"
        >
          Cancelar
        </CButton>
        <CButton
          v-if="!informacion"
          class="btn btn-success"
          @click="editButton()"
        >
          {{ textBoton }}
        </CButton>
      </div>
      <CRow class="justify-content-center">
        <CCol
          md="9"
          class="p-4"
        >
          <CAlert
            color="danger"
            dismissible
            :show.sync="contadorAlertaParametros"
            close-button
          >
            <strong>AVISO</strong> Campos vacios o erroneos

            <CProgress
              :max="10"
              :value="contadorAlertaParametros"
              height="3px"
              color="danger"
              animated
            />
          </CAlert>

          <CAlert
            color="danger"
            dismissible
            :show.sync="contadorAlertaDuplicado"
            close-button
          >
            <strong>AVISO</strong> Ese video ya cuenta con un cuestionario
            <CProgress
              :max="10"
              :value="contadorAlertaDuplicado"
              height="3px"
              color="danger"
              animated
            />
          </CAlert>

          <CAlert
            color="danger"
            dismissible
            :show.sync="contadorAlertaErrorEliminar"
            close-button
          >
            <strong>ERROR</strong> Se ha producido un error al intentar eliminar la pregunta o respuesta

            <CProgress
              :max="10"
              :value="contadorAlertaErrorEliminar"
              height="3px"
              color="danger"
              animated
            />
          </CAlert>
        </CCol>
      </CRow>
      <CModal
        id="modalElimPregunta"
        title="Eliminar Pregunta"
        color="principal"
        :show.sync="deletePregModal"
      >
        <p class="text-muted">
          ¿Esta seguro de que desea eliminar la pregunta junto a todas sus respuestas?
          "{{ question.questions }}"
          Esta acción no se puede deshacer.
        </p>
        <div slot="footer">
          <CButton
            color="primary"
            @click="eliminarPregunta(question.quest_question_id, question.new_question_id)"
          >
            Estoy seguro
          </CButton>

          <CButton
            color="danger"
            @click="deletePregModal = false"
          >
            Cancelar
          </CButton>
        </div>
      </CModal>
      <CModal
        id="modalElimRespuesta"
        title="Eliminar Respuesta"
        color="principal"
        :show.sync="deleteResModal"
      >
        <p class="text-muted">
          ¿Esta seguro de que desea eliminar la respuesta?
          "{{ answer.answer }}"
          Esta acción no se puede deshacer.
        </p>
        <div slot="footer">
          <CButton
            color="primary"
            @click="eliminarRespuesta(answer.quest_answer_id, answer.quest_question_id)"
          >
            Estoy seguro
          </CButton>

          <CButton
            color="danger"
            @click="deleteResModal = false"
          >
            Cancelar
          </CButton>
        </div>
      </CModal>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from 'jquery';
import {getCategoriasData} from '../../utils/utils';

/**
 * Función para la obtencion de los videos
 * 
 * @returns {Promise<any>} Una promesa que al completarse devuelve todos los videos
 */
async function getVideoData() {
  let data;
  const token = localStorage.token;
  await axios.get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_VIDEOS, {
      headers: {
        Authorization: token,
      },
    }).then((result) => {
      data = result.data;
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return data;
}

/**
 * Función para tomar los datos de un cuestionario con un id dado
 * 
 * @param {number} id - El ID del cuestionario a obtener
 * 
 * @returns {Promise<any>} Promesa que al completarse devuelve el cuestionario solicitado
 */
async function getCuestionarioData(id) {
  let data;
  const token = localStorage.token;
  try {
    await axios.get(
        process.env.VUE_APP_URL +
          process.env.VUE_APP_URL_CUESTIONARIO +
          id,
        {
          headers: {
            Authorization: token,
          },
        }
      ).then((result) => {
        data = result.data;
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            window.location = "#/pages/login";
          }
          if (error.response.status == 404) {
            window.location = "#/404";
          }
          if (error.response.status == 500) {
            window.location = "#/500";
          }
        }
      });
    return data;
  } catch (error) {
    window.location = "#/500";
  }
}
/**
 * Función para formatear los datos y prepararlos para la creacion
 * 
 * @returns {object} El cuestionario con el formato correcto para su creación
 */
function getDataReadyToCreate() {
  let title = $('#title').val();
  let descripcion = $('#description').val();
  let video = parseInt($('#videos').children('option:selected').val());
  let preguntas = getQuestionData($('.question').children('input'));
  let respuestas = getAnswerData($('.answer').children('input'));

  
  for(const pregunta in preguntas) {
    preguntas[pregunta].answers = [];

    for(const respuesta in respuestas) {
      if(respuestas[respuesta].new_question_id == preguntas[pregunta].new_question_id)
        preguntas[pregunta].answers.push(respuestas[respuesta].answer);
    }
  }

  let new_cuestionario = {
    title: title,
    description: descripcion,
    video_id: video,
    questions: preguntas
  };

  return new_cuestionario;
}

/**
 * Función para formatear los datos introducidos en las diferentes preguntas
 * 
 * @param {object[]} questions - Un arreglo con los campos donde se han introducido las preguntas
 * 
 * @returns {object[]} Las preguntas formateadas para su modificacion u envío
 */
function getQuestionData(questions) {
  let questionsArray = [];
  for(const question of questions) {
    questionsArray.push({
      id: $(question).attr('question_id'),
      new_question_id: $(question).attr('new_question_id'),
      question_body: $(question).val()
    });
  }

  return questionsArray;
}

/**
 * Función para formatear los datos introducidos en las diferentes respuestas
 * 
 * @param {object[]} answers - Un arreglo con los campos donde se han introducido las respuestas
 * 
 * @returns {object[]} Las respuestas formateadas para su modificacion u envío
 */
function getAnswerData(answers) {
  let answersArray = [];
  for(const answer of answers) {
    answersArray.push({
      id: $(answer).attr('answer_id'),
      question_id: $(answer).attr('question_id'),
      new_question_id: $(answer).attr('new_question_id'),
      answer: $(answer).val()
    });
  }

  return answersArray;
}

/**
 * Función para la obtención de las respuestas de una pregunta dada
 * 
 * @param {object[]} answers - Las respuestas a comprobar si pertenecen a la pregunta
 * @param {object} question - La pregunta a la cual se comprobara si pertenecen las preguntas
 * 
 * @returns {object[]} Un arreglo con todas las respuestas pertenecientes a esa pregunta
 */
function getNewAnswersArray(answers, question) {
  let answersArray = [];
  for(const answer of answers) {
    if($(answer).attr('new_question_id') == question.new_question_id)
      answersArray.push($(answer).val());
  }

  return answersArray;
}

export default {
  name: "EditarCuestionario",
  data() {
    return {
      cuestionario: {},
      question: {},
      answer: {},
      temp: 0,
      tempC: 0,
      tempV: 0,
      temp_question_id: 0,
      categorias: [],
      videos: [],
      newQuestions: [],
      newAnswers: [],
      id: null,
      titulo: "Nuevo cuestionario",
      textBoton: "Crear cuestionario",
      informacion: false,
      showQuestions: false,
      contadorAlertaParametros: 0,
      contadorAlertaErrorEliminar: 0,
      contadorAlertaDuplicado: 0, 
      collapseDuration: 300,
      deletePregModal: false,
      deleteResModal: false
    };
  },
  computed: {
    infoVideo: function() {
      return this.videos.filter(i => i.video_id === this.cuestionario.video_id);
    }
  },
  created() {
    this.id = this.$route.params.id;
    if(this.$route.params.nombre == "infoCuestionario")
      this.informacion = true;
    else
      this.informacion = false;

    this.getCategorias();
  },
  methods: {
    /**
     * Función que se encarga de llamar a todos las demas para cargar los datos del cuestionario
     * 
     * @returns {object} El cuestionario listo para mostrarselo al usuario
     */
    recibirDatos() {
      if (!this.id) {
        if(this.temp > 0)
          return this.cuestionario

        this.showQuestions = true;
        this.cuestionario = {
          questions: [ {
            questions: "",
            new_question_id: ++this.temp_question_id,
            answer: [""]
          }]
        }

        this.$set(this.cuestionario.questions[0].answer, '_toggled', true);

        this.temp++;
        return this.cuestionario;
      }

      if (this.temp > 0) {
        return this.cuestionario;
      }

      this.titulo = "Editar cuestionario";
      this.textBoton = "Editar";

      let data = getCuestionarioData(this.id);
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }

        this.cuestionario = this.checkAndPrepareData(result);

        $('#videos').val(this.cuestionario.video_id).change();
        $(`#videos option[value=${this.cuestionario.video_id}]`).css({'font-weight': 'bold'});
      });

      this.temp++;
      return this.cuestionario;
    },
    /**
     * Función que se encarga de recibir los datos por parametros
     * Y formatearlos para su correcta representacion
     * 
     * @param {object} data - Los datos a formatear para su correcta representación
     * 
     * @returns {object} Los datos formateados
     */
    checkAndPrepareData(data) {
      if(data.questions) {
        for(const question in data.questions) {
          if(data.questions[question].quest_question_id === null)
            data.questions[question].new_question_id = ++this.temp_question_id;

          if(!data.questions[question].answer) 
            data.questions[question].answer = [{}];
        }
      } else {
        data.questions = [{
            questions: "",
            new_question_id: ++this.temp_question_id,
            answer: []
        }];
      }

      return data;
    },
    volver() {
      window.location = "#/cuestionario";
    },
    /**
     * Función que se encargara de modificar o crear el cuestionario correspondiente
     */
    editButton() {
      let title = $('#title').val();
      let descripcion = $('#description').val();
      let video = parseInt($('#videos').children('option:selected').val());
      let preguntas = $('.question').children('input');
      let respuestas = $('.answer').children('input');
      let token = localStorage.token;

      if (!this.id) {
        axios.post(
          process.env.VUE_APP_URL +
          process.env.VUE_APP_URL_CUESTIONARIO +
          "new",
          getDataReadyToCreate(),
          {
            headers: {
              Authorization: token
            }
          }
        ).then(() => {
          this.$router.push("/cuestionario");
        }).catch((error) => {
          if (error.response) {
            if (error.response.status == 400) {
              this.mostrarFalloParametros();
            }
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/404";
            }
            if (error.response.status == 409) {
              this.mostrarFalloDuplicado();
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
        return;
      }

      axios.patch(
        process.env.VUE_APP_URL +
        process.env.VUE_APP_URL_CUESTIONARIO +
        this.id,
        {
          video_id: (video != this.cuestionario.video_id) ? video : undefined,
          quest_title: title,
          quest_description: descripcion
        },
        {
          headers: {
            Authorization: token
          }
        }
      ).then(() => {
        this.modifyOrAddQuestionsAndAnswers(token, preguntas, respuestas);
      }).catch((error) => {
        if(error.response) {
          if (error.response.status == 401) {
            window.location = "#/pages/login";
          }
          if (error.response.status == 404) {
            window.location = "#/404";
          }
          if (error.response.status == 409) {
            this.mostrarFalloDuplicado();
          }
          if (error.response.status == 500) {
            window.location = "#/500";
          }
        }
      });
    },
    /**
     * Función que se encargara de modificar o crear las diferentes preguntas y respuestas
     * 
     * @param {string} token - El token de autenticacion
     * @param {object[]} preguntas - Las preguntas a modificar o crear
     * @param {object[]} respuestas - Las respuestas a modificar o crear
     */
    modifyOrAddQuestionsAndAnswers(token, preguntas, respuestas) {
      for(const question of getQuestionData(preguntas)) {
        if(question.id) {
          axios.patch(
            process.env.VUE_APP_URL +
            process.env.VUE_APP_URL_CUESTIONARIO +
            process.env.VUE_APP_URL_QUESTIONS + 
            question.id,
            {
              question: question.question_body
            },
            {
              headers: {
                Authorization: token
              }
            }
          ).then(() => {
            for(const answer of getAnswerData(respuestas)) {
              if(answer.question_id == question.id && !isNaN(answer.id)) {
                axios.patch(
                  process.env.VUE_APP_URL +
                  process.env.VUE_APP_URL_CUESTIONARIO +
                  process.env.VUE_APP_URL_ANSWERS + 
                  answer.id,
                  {
                    answer: answer.answer
                  },
                  {
                    headers: {
                      Authorization: token
                    }
                  }
                );
              }

              if(answer.question_id == question.id && isNaN(answer.id)) {
                axios.post(
                  process.env.VUE_APP_URL + 
                  process.env.VUE_APP_URL_CUESTIONARIO + 
                  process.env.VUE_APP_URL_ANSWERS +
                  "add",
                  {
                    id_quest_question: question.id,
                    answer: answer.answer
                  },
                  {
                    headers: {
                      Authorization: token
                    }
                  }
                );
              }
            }
          });
        } else {
          axios.post(
            process.env.VUE_APP_URL + 
            process.env.VUE_APP_URL_CUESTIONARIO + 
            process.env.VUE_APP_URL_QUESTIONS,
            {
              id_quest: this.id,
              question: question.question_body,
              answers: getNewAnswersArray(respuestas, question)
            },
            {
              headers: {
                Authorization: token
              }
            }
          );
        }
      }

      window.location = "#/cuestionario/infoCuestionario/" + this.id;
    },
    /**
     * Función para mostrar u ocultar las preguntas
     */
    togglePreguntas() {
      this.showQuestions = !this.showQuestions;
    },
    /**
     * Función para ocultar o mostrar las respuestas
     * 
     * @param {number} question - El ID de la pregunta existente de la mostrar u ocultar sus respuestas
     * @param {number} new_question - El ID de la nuewva pregunta de la mostrar u ocultar sus respuestas
     */
    toggleRespuestas(question, new_question) {
      if(question) {
        for(const i in this.cuestionario.questions) {
          if(this.cuestionario.questions[i].quest_question_id === question) {
            this.$set(this.cuestionario.questions[i].answer, '_toggled', !this.cuestionario.questions[i].answer._toggled);

            break;
          }
        }
        return;
      }

      for(const i in this.cuestionario.questions) {
        if(this.cuestionario.questions[i].new_question_id === new_question) {
          this.$set(this.cuestionario.questions[i].answer, '_toggled', !this.cuestionario.questions[i].answer._toggled);

          break;
        }
      }
    },
    /**
     * Función para la obtención de las categorías
     * 
     * @returns {object[]} Las categorías obtenidas
     */
    getCategorias() {
      if (this.tempC > 0) {
        return this.categorias;
      }
      let data = getCategoriasData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.categorias = result;
        this.tempC++;
        this.getVideos();
      });
      return this.categorias;
    },
    /**
     * Función para la obtención de los videos
     * 
     * @returns {object[]} Los videos obtenidos
     */
    getVideos() {
      if(this.tempV > 0)
        return this.videos;

      let data = getVideoData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }

        this.videos = [];

        for(const i in result) {
          let element = {};
          element.video_id = result[i].video_id;
          element.video_name = result[i].video_name;
          element.category_id = result[i].video_category;

          for(const j in this.categorias) {
            if(this.categorias[j].category_id == element.category_id) {
              element.category_name = this.categorias[j].category_name;
              break;
            }
          }

          this.videos.push(element);
        }
      });
      this.tempV++;
      this.recibirDatos();
      return this.videos;
    },
    /**
     * Función para añadir una pregunta al cuestionario
     */
    addQuestion() {
      if(this.id)
        this.cuestionario.questions.push({
          questions: "",
          new_question_id: ++this.temp_question_id,
          answer: []
        });
      else {
        this.cuestionario.questions.push({
          questions: "",
          new_question_id: ++this.temp_question_id,
          answer: [""]
        });

        this.$set(this.cuestionario.questions[this.temp_question_id - 1].answer, '_toggled', true);
      }
    },
    /**
     * Función para añadir una respuesta a una pregunta dada
     * 
     * @param {number} id - El id de la pregunta existente a la que añadir la respuesta
     * @param {number} new_question_id - El id de la nueva pregunta a la que añadir la respuesta
     */
    addAnswer(id, new_question_id) {
      if(this.id) {
        if(id)
          for(const question in this.cuestionario.questions) {
            if(this.cuestionario.questions[question].quest_question_id == id) {
              this.cuestionario.questions[question].answer.push({
                answers: ""
              });

              break;
            }
          }
        else
          for(const question in this.cuestionario.questions) {
            if(this.cuestionario.questions[question].new_question_id == new_question_id) {
              this.cuestionario.questions[question].answer.push("");

              break;
            }
          }

        return;
      }

      for(const question in this.cuestionario.questions) {
        if(this.cuestionario.questions[question].new_question_id == new_question_id) {
          this.cuestionario.questions[question].answer.push("");

          break;
        }
      }
    },
    /**
     * Función para eliminar una pregunta del cuestionario
     * 
     * @param {number} quest_question_id - El ID de la pregunta existente a eliminar del cuestionario y la BBDD
     * @param {number} new_question_id - El ID de la nueva pregunta que no esta en la BBDD a eliminar
     */
    eliminarPregunta(quest_question_id, new_question_id) {
      this.deletePregModal = false;
      let token = localStorage.token;
      if(quest_question_id) {
        axios.delete(
          process.env.VUE_APP_URL +
          process.env.VUE_APP_URL_CUESTIONARIO +
          process.env.VUE_APP_URL_QUESTIONS +
          quest_question_id,
          {
            headers: {
              Authorization: token
            }
          }
        ).then(() => {
          this.cuestionario.questions = this.cuestionario.questions.filter((question) => {
            return question.quest_question_id != quest_question_id;
          });
        }).catch((error) => {
          if(error.response) {
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            } else {
              this.mostrarFalloEliminar();
            }
          }
        });
      }

      if(new_question_id) {
        this.cuestionario.questions = this.cuestionario.questions.filter((question) => {
          return question.new_question_id != new_question_id;
        });
      }
    },
    /**
     * Función para eliminar una respuesta existente
     * 
     * @param {number} quest_answer - El id de la respuesta a eliminar
     * @param {number} quest_question_id - El id de la pregunta a la que pertenece la respuesta a eliminar
     */
    eliminarRespuesta(quest_answer_id, quest_question_id) {
      this.deleteResModal = false;
      if(quest_question_id && quest_answer_id) {
        let token = localStorage.token;
        axios.delete(
          process.env.VUE_APP_URL +
          process.env.VUE_APP_URL_CUESTIONARIO +
          process.env.VUE_APP_URL_ANSWERS +
          quest_answer_id,
          {
            headers: {
              Authorization: token
            }
          }
        ).then(() => {
          for(const question in this.cuestionario.questions) {
            if(this.cuestionario.questions[question].quest_question_id == quest_question_id) {
              this.cuestionario.questions[question].answer = this.cuestionario.questions[question].answer.filter((answer) => {
                return answer.quest_answer_id != quest_answer_id;
              });

              break;
            }
          }
        }).catch((error) => {
          if(error.response) {
            if (error.response.status == 401) {
              window.location = "#/pages/login";
            } else {
              this.mostrarFalloEliminar();
            }
          }
        });
      }
    },
    mostrarPregDeleteModal(pregunta) {
      this.question = pregunta;
      this.deletePregModal = true;
    },
    mostrarResDeleteModal(respuesta, pregId) {
      respuesta.quest_question_id = pregId;
      this.answer = respuesta;
      this.deleteResModal = true;
    },
    mostrarFalloParametros() {
      this.contadorAlertaParametros = 10;
    },
    mostrarFalloDuplicado() {
      this.contadorAlertaDuplicado = 10;
    },
    mostrarFalloEliminar() {
      this.contadorAlertaErrorEliminar = 10;
    },
  },
};
</script>